<template>
  <header
    class="header header--list"
    :class="(showGuestHeader || isPreviewList) ? 'header--list-preview' : ''"
  >
    <section class="header-preview-list" v-show="showGuestHeader">
      <div class="header-preview-list__wrapper">
        <div class="header-preview-list__left">
          <span
            class="button button--grey-6 button--sm button-icoStart"
            @click.prevent="redirectToOwnerList()"
          >
            <i class="uil uil-arrow-left"></i>
            {{ $t("guest.goBackToOwnList") }}
          </span>
        </div>
        <div class="header-preview-list__title">{{ $t("guest.howGuestSeeList") }}</div>
        <div class="header-preview-list__actions">
          <ModalQuitarPublicidad :showModal="showTimedAdsenseModal" />
          <ShareListHucha
            ref="share"
            buttonVariant="button--primary button--sm"
            key="share1"
          />
        </div>
      </div>
    </section>

    <!-- afegir: si-es-mostra ? 'header--list-preview' : '' -->
    <section class="top-preview-list" v-show="isPreviewList && !isLoggedIn">
      <div class="top-preview-list__wrapper">
        <div class="top-preview-list__left">
          <div class="top-preview-list__back" @click="$router.push({ name: 'HomeFR' })">
            <i class="uil uil-arrow-left"></i> <span>Retourner</span>
          </div>
        </div>
        <div class="top-preview-list__center">
          <div class="top-preview-list__title">Exemple de liste de naissance</div>
          <div class="top-preview-list__description">Voici à quoi ressemblera votre liste lorsque vous la partagerez.</div>
        </div>
        <div class="top-preview-list__actions">
          <bbButton
            label="Créer ma liste"
            class="button--accent button--sm-sm"
            @click="$router.push({ name: 'creez-votre-liste' })"
          ></bbButton>
        </div>
      </div>
    </section>

    <div class="header__container header__container--guest">
      <div class="header__logo header__logo--guest" @click="link">
        <img src="../assets/img/logos/logo-hellobb-dark.svg" alt="Logo HelloBB" />
      </div>
      <div class="header__cart" v-show="guestListHasHelloBBProducts">
        <Cart />
      </div>
    </div>
  </header>
</template>
<script>
import { mapGetters } from "vuex";
import Cart from "./components/Cart";
import ShareListHucha from "../modules/ownerlist/components/ShareListHucha.vue";
import ModalQuitarPublicidad from "../modules/ownerlist/components/ModalQuitarPublicidad.vue";
import bbButton from "../components/buttons/bbButton.vue";

export default {
  name: "HeaderList",
  data() {
    return {
      showGuestHeader: false,
      showTimedAdsenseModal: false,
    };
  },
  components: {
    ShareListHucha,
    Cart,
    ModalQuitarPublicidad,
    bbButton,
  },
  computed: {
    ...mapGetters({
      user: "user",
      list: "myList",
      shouldDisplayAds: "shouldDisplayAds",
      guestListHasHelloBBProducts: "guestListHasHelloBBProducts",
      isPreviewList: "isPreviewList",
    }),
    isLoggedIn() {
      return !!this.user;
    },
  },
  methods: {
    link() {
      this.$router.push({ name: "guest", params: { id: this.list.id } });
    },
    redirectToOwnerList() {
      this.$router.push({ name: "owner-list" });
    },
    async sleep(ms = 0) {
      return new Promise((resolve) => {
        setTimeout(() => {
          this.$nextTick(() => {
            resolve();
          });
        }, ms);
      });
    },
    modalIsOpen() {
      return !!document.querySelector(".modal[data-component='ModalDialog']");
    },
    async waitUntilModalIsClosed(delay = 500) {
      while (this.modalIsOpen()) {
        await this.sleep(delay);
      }
    },
  },
  mounted() {
    this.showGuestHeader = this.user && this.user.listId == this.$route.params.id;

    if (this.showGuestHeader && this.shouldDisplayAds) {
      // After a few seconds, show a modal informing the user about removing
      // ads.

      const showModalAfterSeconds = 10;
      console.log(`Will show adsense modal after ${showModalAfterSeconds} seconds.`);

      setTimeout(async () => {
        console.log("Ensuring there's no currently open modals.");

        // If there's a modal open, we wait until it is closed.
        //
        // If we don't do this, the modal will be replaced, and there will be
        // an error.
        await this.waitUntilModalIsClosed();

        console.log("Showing adsense modal.");
        this.showTimedAdsenseModal = true;
      }, showModalAfterSeconds * 1000);
    } else {
      console.log("Will not show adsense modal.");
    }
  },
};
</script>
<style></style>
