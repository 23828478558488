<template>
  <portal to="modals" v-if="show">
    <transition name="bbFade" @after-enter="showContent = true" appear>
      <div class="modal" v-show="show" @click="dismiss()" data-component="ModalDialog">
        <transition name="bbUp" @after-leave="dismiss()" appear>
          <div
            class="modal__card"
            :class="targetClass + ' ' + (hasFooter ? 'modal__card--with-footer' : '')"
            v-show="showContent"
            @click.stop=""
          >
            <div class="modal__dialog">
              <div class="modal__header">
                <div class="modal__back">
                  <div v-show="back" class="modal-back">
                    <a @click.prevent="$emit('back')">
                      <div class="modal-back__name">
                        <img
                          src="../../assets/img/ico/ico-arrow-back.svg"
                          loading="lazy"
                          alt="Volver"
                          class="back__arrow"
                        />
                      </div>
                    </a>
                  </div>
                </div>
                <div class="modal__close">
                  <img
                    src="../../assets/img/ico/ico-close.svg"
                    alt="Cerrar"
                    @click="close()"
                  />
                </div>
              </div>
              <div class="modal__content">
                <slot></slot>
              </div>
              <div class="modal__footer" v-if="hasFooter">

                <div class="cta-contributions" v-if="footer === 'contributions'">
                  <h3 class="cta-contributions__title">
                    {{ $t("mylist.share.enablePiggybank") }}
                  </h3>
                  <div class="cta-contributions__content">
                    {{ $t("mylist.share.enablePiggybankDescription") }}
                  </div>

                  <div class="cta-contributions__actions">
                    <button class="button button--accent" @click="goToPiggybank()">
                      {{ $t("mylist.share.enablePiggybankButton") }}
                    </button>

                    <a href="/mi-hucha/crear#comoFunciona">
                      {{ $t("mylist.share.howItWorkds") }}
                    </a>
                  </div>
                </div>

                <div class="bba-banner" v-if="footer === 'adsense'">
                  <div class="bba-banner__header">
                    <strong>Publicidad</strong>
                    <span class="link link--underline" @click="upgradeToPremium">Quitar publicidad</span>
                  </div>
                  <div class="bba-banner__main">
                    <Adsense :adType="adType"></Adsense>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </portal>
</template>

<script>
function is_server() {
  return !(typeof window != "undefined" && window.document);
}

import { mapGetters } from "vuex";
import Adsense, { TYPE_IMAGE_SIDE } from "../../modules/adsense/Adsense";

export default {
  components: {
    Adsense,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    back: {
      type: Boolean,
      default: false,
    },
    targetClass: {
      type: String,
      default: "",
    },
    showContributionsFooter: {
      type: Boolean,
      default: false,
    },
    showAdsenseFooter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showContent: false,
      adType: TYPE_IMAGE_SIDE,
    };
  },
  computed: {
    ...mapGetters({
      hidePiggyBank: "hidePiggyBank",
    }),
    footer() {
      if (this.showAdsenseFooter) {
        return "adsense";
      }
      if (this.showContributionsFooter && !this.hidePiggyBank) {
        return "contributions";
      }
      return null;
    },
    hasFooter() {
      return !!this.footer;
    },
  },
  methods: {
    dismiss() {
      this.$emit("close");
    },
    close() {
      document.body.classList.remove("modal-open");
      this.$store.commit("toggleLandbot", true);
      //if route name is 'constructor-product' then go back to the previous page
      if (this.$route.name === "constructor-product") {
        this.$router.go(-1);
      } else {
        this.showContent = false
      }
    },
    upgradeToPremium() {
      return this.$store.dispatch("upgradeToPremium");
    },
    goToPiggybank() {
      if(this.$utils.isApp()) location.href = process.env.APP_URL + "mi-hucha/crear";
      else this.$router.push({ name: 'hucha-crear' })
    },
  },
  created() {
    const escapeHandler = (e) => {
      if (e.key === "Escape" && this.show) {
        this.dismiss();
      }
    };
    if (!is_server()) {
      document.addEventListener("keydown", escapeHandler);
    }

    this.$once("hook:destroyed", () => {
      if (!is_server()) {
        document.removeEventListener("keydown", escapeHandler);
      }
    });
    this.$watch(
      () => this.show,
      (show) => {
        if (show) {
          this.$store.commit("toggleLandbot", false);
          if (typeof document !== 'undefined') document.body.classList.add("modal-open");
        } else {
          this.$store.commit("toggleLandbot", true);
          if (typeof document !== 'undefined') document.body.classList.remove("modal-open");
        }
      },
      { immediate: true }
    );
  },
};
</script>
