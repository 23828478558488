<template>
  <div class="social-icons">
    <a
      :href="$t('social.instagramUrl', iconsLang)"
      class="social-icons__item"
      target="_blank"
      aria-label="Link a Instagram"
      rel="nofollow"
    >
      <img
        loading="lazy"
        src="../../assets/img/ico/ico-social-instagram.svg"
        alt="Instagram"
      />
    </a>
    <a
      :href="$t('social.pinterestUrl', iconsLang)"
      rel="nofollow"
      class="social-icons__item"
      target="_blank"
      aria-label="Link a Pinterest"
    >
      <img
        loading="lazy"
        src="../../assets/img/ico/ico-social-pinterest.svg"
        alt="Pinterest"
      />
    </a>
    <a
      :href="$t('social.facebookUrl', iconsLang)"
      rel="nofollow"
      class="social-icons__item"
      target="_blank"
      aria-label="Link a Facebook"
    >
      <img
        loading="lazy"
        src="../../assets/img/ico/ico-social-facebook.svg"
        alt="Facebook"
      />
    </a>
  </div>
</template>
<script>
export default {
  name: "FooterSocialIcons",
  props: {
    iconsLang: {
      type: String,
      default: "es-ES"
    }
  },
};
</script>
