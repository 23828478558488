<template>
  <div class="h-sticky">
    <template v-if="$route.path.startsWith('/mi-lista') && show">
      <AlertHucha @closeAlertHucha="closeAlertHucha">
        <template v-if="$route.path === '/mi-lista'">
          <div v-html="$t('mylist.headerEnablePiggybank')"></div>
        </template>
        <template v-else-if="$route.path.startsWith('/mi-lista/comprar')">
          <div v-html="$t('mylist.headerEnablePiggybankProduct')"></div>
        </template>
      </AlertHucha>
    </template>

    <header-partial :headerLang="headerLang" />
  </div>
</template>

<script>
import AlertHucha from "../components/ui/alerts/AlertHucha.vue";
import HeaderPartial from "./components/HeaderPartial";
import { mapGetters } from "vuex";

export default {
  name: "Header",
  components: {
    HeaderPartial,
    AlertHucha,
  },
  props: {
    headerLang: {
      type: String,
      default: "es-ES",
    },
  },
  data() {
    return {
      show: false,
      closedByTheUser: false,
    };
  },
  computed: {
    ...mapGetters({
      contributionsFlag: "contributionsFlag",
      list: "ownerList",
      isAuthenticated: "isAuthenticated",
      hidePiggyBank: "hidePiggyBank",
    }),
  },
  methods: {
    handleScroll() {
      const currentScrollPosition = window.scrollY;

      if (currentScrollPosition > 100) {
        this.show = (
          !this.hidePiggyBank
          && this.contributionsFlag
          && (this.list.stripeAccountId == null  || this.list.stripeAccountId == "")
          && this.list
          && this.list.products
          && this.list.products.length > 0
          && !this.closedByTheUser
        );
      } else {
        this.show = false
      }
    },
    closeAlertHucha() {
      this.show = false;
      this.closedByTheUser = true;
    },
    setUpAlertHucha() {
      const alertHucha = localStorage.getItem("alertHucha");
      if (alertHucha) {
        const item = JSON.parse(alertHucha);
        const now = new Date();
        if (now.getTime() > item.expiry) {
          localStorage.removeItem("alertHucha");
        } else {
          this.closedByTheUser = item.value === "false";
        }
      }
      if (!this.isAuthenticated) {
        this.show = false;
      }
      else if(Object.keys(this.list).length !== 0)
      {
        this.show = (
          !this.hidePiggyBank
          && this.contributionsFlag
          && (this.list.stripeAccountId == null  || this.list.stripeAccountId == "")
          && this.list
          && this.list.products
          && this.list.products.length > 0
          && window.scrollY > 100
          && !this.closedByTheUser
        );
      }


      window.addEventListener("scroll", this.handleScroll);
    },
  },
  mounted() {
    this.setUpAlertHucha();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    isAuthenticated() {
      if (this.isAuthenticated) {
        this.setUpAlertHucha();
      }
    },
  }
};
</script>
