import es from "./literals/es.json";
import esMX from "./literals/es-MX.json";
import frFR from "./literals/fr-FR.json";
import itIT from "./literals/it-IT.json";

export default {
    "es": es,
    "es-MX": esMX,
    "fr-FR": frFR,
    "it-IT": itIT,
}
